import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { UserReducer } from './user/user.reducer';
import { RoleReducer } from './role/role.reducer';
import { ModuleReducer } from './module/module.reducer';
import { VendorReducer } from './vendorsStore/vendor.reducer';
import { RawReducer } from './raw-material/raw-material.reducer';
import { CategoryReducer } from './category/category.reducer';
import { SubCategoryReducer } from './sub-category/sub-category.reducer';
import { ProductReducer } from './product/product.reducer';
import { ProductTypeReducer } from './product-type/product-type.reducer';
import { ProductSizeReducer } from './product-size/product-size.reducer';
import { ProfileReducer } from './profile/profile.reducer';
import { OrderReducer } from './order/order.reducer';
import { CastingReducer } from './manufacturing/casting/casting.reducer';
import { TurningReducer } from './manufacturing/turning/turning.reducer';
import { ProcessReducer } from './manufacturing/process/process.reducer';
import { PlatingReducer } from './manufacturing/plating/plating.reducer';
import { DishReducer } from './dish/dish.reducer';
import { NickelReducer } from './manufacturing/nickel/nickel.reducer';
import { CPReducer } from './manufacturing/cp/cp.reducer';
import { DullReducer } from './manufacturing/dull/dull.reducer';
import { BlackReducer } from './manufacturing/black/black.reducer';
import { BrassReducer } from './manufacturing/brass/brass.reducer';
import { GoldReducer } from './manufacturing/gold/gold.reducer';
import { CopperReducer } from './manufacturing/copper/copper.reducer';
import { TinReducer } from './manufacturing/tin/tin.reducer';
import { ZincReducer } from './manufacturing/zinc/zinc.reducer';
import { ChromeReducer } from './manufacturing/chrome/chrome.reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user : UserReducer,
    role : RoleReducer,
    module : ModuleReducer,
    vendor : VendorReducer,
    raw : RawReducer,
    category: CategoryReducer,
    subCategory: SubCategoryReducer,
    product: ProductReducer,
    type: ProductTypeReducer,
    size: ProductSizeReducer,
    profile: ProfileReducer,
    order: OrderReducer,
    casting: CastingReducer,
    turning: TurningReducer,
    process: ProcessReducer,
    plating: PlatingReducer,
    dish: DishReducer,
    nickel: NickelReducer, cp: CPReducer, dull: DullReducer,
    black: BlackReducer, brass: BrassReducer, gold: GoldReducer,
    copper: CopperReducer, tin: TinReducer, zinc: ZincReducer, chrome: ChromeReducer,
});

export default reducer;
