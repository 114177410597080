import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from 'ui-component/Loadable';

const List = Loadable(lazy(() => import('./VendorList')));
const VendorCreate = Loadable(lazy(() => import('./VendorCreate')));
const VendorUpdate = Loadable(lazy(() => import('./VendorUpdate')));
const View = Loadable(lazy(() => import('./View')));

const vendorRoutes = [
    {
        path: '/vendors/create/',
        element: <VendorCreate />,
        auth: authRoles.admin
    },
    {
        path: '/vendors/',
        element: <List />,
        auth: authRoles.admin
    },
    {
        path: '/vendors/edit/:id',
        element: <VendorUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/vendors/view/:id',
        element: <View />,
        auth: authRoles.admin
    }
];

export default vendorRoutes;