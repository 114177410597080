import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from 'ui-component/Loadable';

const Products = Loadable(lazy(() => import('./Products')));
const ProductCreate = Loadable(lazy(() => import('./ProductCreate')));
const ProductUpdate = Loadable(lazy(() => import('./ProductUpdate')));
const View = Loadable(lazy(() => import('./View')));

const SizeCreate = Loadable(lazy(() => import('./size/SizeCreate')));
const SizeUpdate = Loadable(lazy(() => import('./size/SizeUpdate')));

const TypeCreate = Loadable(lazy(() => import('./type/TypeCreate')));
const TypeUpdate = Loadable(lazy(() => import('./type/TypeUpdate')));

const DishCreate = Loadable(lazy(() => import('./dish/DishCreate')));
const DishUpdate = Loadable(lazy(() => import('./dish/DishUpdate')));

const productRoutes = [
    {
        path: '/products/',
        element: <Products name="product" />,
        auth: authRoles.admin
    },
    {
        path: '/products/create/',
        element: <ProductCreate />,
        auth: authRoles.admin
    },
    {
        path: '/products/edit/:id',
        element: <ProductUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/products/view/:id',
        element: <View />,
        auth: authRoles.admin
    },
    {
        path: '/product-type',
        element: <Products name="type" />,
        auth: authRoles.admin
    },
    {
        path: '/product-type/create',
        element: <TypeCreate />,
        auth: authRoles.admin
    },
    {
        path: '/product-type/edit/:id',
        element: <TypeUpdate />,
        auth: authRoles.admin
    },

    {
        path: '/product-size/create',
        element: <SizeCreate />,
        auth: authRoles.admin
    },
    {
        path: '/product-size/edit/:id',
        element: <SizeUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/product-size',
        element: <Products name="size" />,
        auth: authRoles.admin
    },

    {
        path: '/dish/create',
        element: <DishCreate />,
        auth: authRoles.admin
    },
    {
        path: '/dish/edit/:id',
        element: <DishUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/dish',
        element: <Products name="dish" />,
        auth: authRoles.admin
    }
];

export default productRoutes;