import PRODUCT_TYPE_ACTION_TYPES from './product-type.types';

export const PRODUCT_TYPE_INITIAL_STATE = {
  typeList: [],
};

export const ProductTypeReducer = (state = PRODUCT_TYPE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_TYPE_ACTION_TYPES.LOAD_PRODUCT_TYPE: {
      return { ...state, typeList: payload };
    }
    default:
      return state;
  }
};
