import BLACK_ACTION_TYPES from './black.types';

export const BLACK_INITIAL_STATE = {
  blackList: [],
};

export const BlackReducer = (state = BLACK_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case BLACK_ACTION_TYPES.LOAD_BLACK: {
      return { ...state, blackList: payload };
    }
    default:
      return state;
  }
};
