import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import Loadable from 'ui-component/Loadable';

const Dull = Loadable(lazy(() => import('./Dull')));
const Create = Loadable(lazy(() => import('./Create')));
const Update = Loadable(lazy(() => import('./Update')));
const View = Loadable(lazy(() => import('./View')));

const routeTypes = [
    { path: 'dull', type: 'inward' },
    { path: 'dull-outward', type: 'outward' }
];

const DullRoutes = routeTypes.flatMap(({ path, type }) => [
    {
        path: `/${path}/create`,
        element: <Create type={type} />,
        auth: authRoles.admin
    },
    {
        path: `/${path}`,
        element: <Dull type={type} />,
        auth: authRoles.admin
    },
    {
        path: `/${path}/edit/:id`,
        element: <Update type={type} />,
        auth: authRoles.admin
    },
    {
        path: `/${path}/view/:id`,
        element: <View type={type} />,
        auth: authRoles.admin
    }
]);

export default DullRoutes;