import RAW_ACTION_TYPES from './raw-material.types';

export const RAW_INITIAL_STATE = {
  rawLists: [],
};

export const RawReducer = (state = RAW_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case RAW_ACTION_TYPES.LOAD_RAWS: {
      return { ...state, rawLists: payload };
    }
    default:
      return state;
  }
};
