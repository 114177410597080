import COPPER_ACTION_TYPES from './copper.types';

export const COPPER_INITIAL_STATE = {
  copperList: [],
};

export const CopperReducer = (state = COPPER_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case COPPER_ACTION_TYPES.LOAD_COPPER: {
      return { ...state, copperList: payload };
    }
    default:
      return state;
  }
};
