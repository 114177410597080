import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'auth/AuthGuard';
import usersRoutes from 'views/users/userRoutes';
import vendorRoutes from 'views/vendorsList/vendorRoutes';
import rawMaterialRoutes from 'views/raw-material/Routes';
import categoryRoutes from 'views/category/Routes';
import subCategoryRoutes from 'views/sub-category/Routes';
import productRoutes from 'views/products/ProductRoutes';
import profileRoutes from 'views/profile/profileRoutes';
import orderRoutes from 'views/order/Routes';
import castingRoutes from 'views/manufacturing/casting/Routes';
import turningRoutes from 'views/manufacturing/turning/Routes';
import processRoutes from 'views/manufacturing/process/Routes';
import platingRoutes from 'views/manufacturing/electro-plating/Routes';
import NickelRoutes from 'views/manufacturing/nickel/Routes';
import CPRoutes from 'views/manufacturing/cp/Routes';
import DullRoutes from 'views/manufacturing/dull/Routes';
import BlackRoutes from 'views/manufacturing/black/Routes';
import BrassRoutes from 'views/manufacturing/brass/Routes';
import GoldRoutes from 'views/manufacturing/gold/Routes';
import CopperRoutes from 'views/manufacturing/copper/Routes';
import TinRoutes from 'views/manufacturing/tin/Routes';
import ZincRoutes from 'views/manufacturing/zinc/Routes';
import ChromeRoutes from 'views/manufacturing/chrome/Routes';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page/list')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        ...usersRoutes,
        ...vendorRoutes,
        ...rawMaterialRoutes,
        ...categoryRoutes,
        ...subCategoryRoutes,
        ...productRoutes,
        ...profileRoutes,
        ...orderRoutes,
        ...castingRoutes,
        ...turningRoutes,
        ...processRoutes,
        ...platingRoutes,
        ...NickelRoutes, ...CPRoutes, ...DullRoutes,
        ...BlackRoutes, ...BrassRoutes, ...GoldRoutes,
        ...CopperRoutes, ...TinRoutes, ...ZincRoutes, ...ChromeRoutes,
    ]
};

export default MainRoutes;
