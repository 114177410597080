import TURNING_ACTION_TYPES from './turning.types';

export const TURNING_INITIAL_STATE = {
  turningList: [],
};

export const TurningReducer = (state = TURNING_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case TURNING_ACTION_TYPES.LOAD_TURNING: {
      return { ...state, turningList: payload };
    }
    default:
      return state;
  }
};
