// material-ui
import logo1 from 'assets/images/logo.svg';
import useAuth from "hooks/useAuth";
import { fileUrl } from 'utils/constant';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
*
*/

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const { user } = useAuth();
    const logo = (user && user.company_id) ? fileUrl + user.company_id?.company_logo : logo1;

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <img src={logo} alt="Berry" width="150"/>
    );
};

export default Logo;
