import {
    IconLayoutDashboard, IconUsers, IconPackage, IconCircleSquare, IconShieldCheck,
    IconBoxMultiple, IconArchive, IconUser, IconArticle, IconFileInvoice
} from '@tabler/icons';

// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            url: '/dashboard/default',
            icon: IconLayoutDashboard,
            type: 'item'
        },
        {
            id: 'vendors',
            title: 'Vendors',
            url: '/vendors',
            icon: IconUsers,
            type: 'item'
        },
        {
            id: 'manufacturing',
            title: 'Manufacturing',
            url: '/',
            icon: IconShieldCheck,
            type: 'collapse',
            children: [
                {
                    id: 'casting',
                    title: 'Casting',
                    url: '/casting',
                    icon: IconShieldCheck,
                    type: 'item'
                },
                {
                    id: 'turning',
                    title: 'Turning',
                    url: '/turning',
                    icon: IconShieldCheck,
                    type: 'item'
                },
                {
                    id: 'electro_plating',
                    title: 'ElectroPlating',
                    url: '/electro-plating',
                    icon: IconShieldCheck,
                    type: 'item'
                },
                {
                    id: 'nickel',
                    title: 'Nickel',
                    url: '/nickel',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'cp',
                    title: 'CP',
                    url: '/cp',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'dull',
                    title: 'Dull',
                    url: '/dull',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'black',
                    title: 'Black',
                    url: '/black',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'brass',
                    title: 'Brass',
                    url: '/brass',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'gold',
                    title: 'Gold',
                    url: '/gold',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'copper',
                    title: 'Copper',
                    url: '/copper',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },{
                    id: 'tin',
                    title: 'tin',
                    url: '/tin',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },{
                    id: 'zinc',
                    title: 'zinc',
                    url: '/zinc',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
                {
                    id: 'chrome',
                    title: 'chrome',
                    url: '/chrome',
                    icon: IconShieldCheck,
                    type: 'item',
                    sub: true
                },
            ]
        },
        {
            id: 'user',
            title: 'User Management',
            url: '/users',
            icon: IconUser,
            type: 'item'
        },
        {
            id: 'raw-materials',
            title: 'Raw Materials',
            url: '/raw-materials',
            icon: IconPackage,
            type: 'item'
        },
        {
            id: 'categories',
            title: 'Categories',
            url: '/categories',
            icon: IconBoxMultiple,
            type: 'item'
        },
        {
            id: 'sub_categories',
            title: 'Sub Categories',
            url: '/sub-categories',
            icon: IconCircleSquare,
            type: 'item'
        },
        {
            id: 'products',
            title: 'Products',
            url: '/products',
            icon: IconArchive,
            type: 'item'
        },
        {
            id: 'orders',
            title: 'Orders',
            url: '/orders',
            icon: IconArticle,
            type: 'item'
        },
        {
            id: 'process',
            title: 'Process',
            url: '/process',
            icon: IconShieldCheck,
            type: 'item'
        },
        {
            id: 'invoice',
            title: 'Invoice',
            url: '/invoice',
            icon: IconFileInvoice,
            type: 'item',
            last_menu: true
        },
    ]
};

export default menuItems;
