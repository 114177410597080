import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import Loadable from 'ui-component/Loadable';

const List = Loadable(lazy(() => import('./List')));
const Create = Loadable(lazy(() => import('./Create')));
const Update = Loadable(lazy(() => import('./Update')));

const processRoutes = [
    {
        path: '/process/create',
        element: <Create />,
        auth: authRoles.admin
    },
    {
        path: '/process',
        element: <List />,
        auth: authRoles.admin
    },
    {
        path: '/process/edit/:id',
        element: <Update />,
        auth: authRoles.admin
    }
];

export default processRoutes;