import CHROME_ACTION_TYPES from './chrome.types';

export const CHROME_INITIAL_STATE = {
  chromeList: [],
};

export const ChromeReducer = (state = CHROME_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CHROME_ACTION_TYPES.LOAD_CHROME: {
      return { ...state, chromeList: payload };
    }
    default:
      return state;
  }
};
