import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from 'ui-component/Loadable';

const Profile = Loadable(lazy(() => import('./Profile')));
const ProfileUpdate = Loadable(lazy(() => import('./ProfileUpdate')));

const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));

const profileRoutes = [
    {
        path: '/profile/',
        element: <Profile />,
        auth: authRoles.admin
    },
    {
        path: '/profile/edit/:id',
        element: <ProfileUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/change-password/',
        element: <ChangePassword />,
        auth: authRoles.admin
    },
];

export default profileRoutes;