import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AlertProvider } from 'contexts/AlertContext';
import { AuthProvider } from 'contexts/JWTAuthContext';
import { SnackbarProvider } from 'contexts/SnackbarContext';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <AuthProvider>
                    <AlertProvider>
                        <SnackbarProvider>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </SnackbarProvider>
                    </AlertProvider>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
