import PLATING_ACTION_TYPES from './plating.types';

export const PLATING_INITIAL_STATE = {
  platingList: [],
};

export const PlatingReducer = (state = PLATING_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case PLATING_ACTION_TYPES.LOAD_PLATING: {
      return { ...state, platingList: payload };
    }
    default:
      return state;
  }
};
