import NICKEL_ACTION_TYPES from './nickel.types';

export const NICKEL_INITIAL_STATE = {
  nickelList: [],
};

export const NickelReducer = (state = NICKEL_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case NICKEL_ACTION_TYPES.LOAD_NICKEL: {
      return { ...state, nickelList: payload };
    }
    default:
      return state;
  }
};
