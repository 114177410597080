import ORDER_ACTION_TYPES from './order.types';

export const ORDER_INITIAL_STATE = {
  orderList: [],
};

export const OrderReducer = (state = ORDER_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_ACTION_TYPES.LOAD_ORDER: {
      return { ...state, orderList: payload };
    }
    default:
      return state;
  }
};
