import CASTING_ACTION_TYPES from './casting.types';

export const CASTING_INITIAL_STATE = {
  castingList: [],
};

export const CastingReducer = (state = CASTING_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CASTING_ACTION_TYPES.LOAD_CASTING: {
      return { ...state, castingList: payload };
    }
    default:
      return state;
  }
};
