import TIN_ACTION_TYPES from './tin.types';

export const TIN_INITIAL_STATE = {
  tinList: [],
};

export const TinReducer = (state = TIN_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case TIN_ACTION_TYPES.LOAD_TIN: {
      return { ...state, tinList: payload };
    }
    default:
      return state;
  }
};
