import SUB_CATEGORY_ACTION_TYPES from './sub-category.types';

export const SUB_CATEGORY_INITIAL_STATE = {
  subCategoryList: [],
};

export const SubCategoryReducer = (state = SUB_CATEGORY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SUB_CATEGORY_ACTION_TYPES.LOAD_SUB_CATEGORY: {
      return { ...state, subCategoryList: payload };
    }
    default:
      return state;
  }
};
