import BRASS_ACTION_TYPES from './brass.types';

export const BRASS_INITIAL_STATE = {
  brassList: [],
};

export const BrassReducer = (state = BRASS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case BRASS_ACTION_TYPES.LOAD_BRASS: {
      return { ...state, brassList: payload };
    }
    default:
      return state;
  }
};
