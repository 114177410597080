import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from 'ui-component/Loadable';

const Users = Loadable(lazy(() => import('./Users')));
// const View = Loadable(lazy(() => import('./View')));
const UserCreate = Loadable(lazy(() => import('./UserCreate')));
const UserUpdate = Loadable(lazy(() => import('./UserUpdate')));
// const UserView = Loadable(lazy(() => import('./View')))

const RoleCreate = Loadable(lazy(() => import('./role/RoleCreate')));
const RoleUpdate = Loadable(lazy(() => import('./role/RoleUpdate')));
const RoleView = Loadable(lazy(() => import('./role/View')));

const ModuleCreate = Loadable(lazy(() => import('./module/ModuleCreate')));
const ModuleUpdate = Loadable(lazy(() => import('./module/ModuleUpdate')));

const usersRoutes = [
    {
        path: '/users/create/',
        element: <UserCreate />,
        auth: authRoles.admin
    },
    {
        path: '/users/',
        element: <Users name="user" />,
        auth: authRoles.admin
    },
    {
        path: '/users/edit/:userId',
        element: <UserUpdate />,
        auth: authRoles.admin
    },
    // {
    //   path: '/users/view/:userId',
    //   element: <UserView />,
    //   auth: authRoles.admin
    // },
    {
        path: '/role',
        element: <Users name="role" />,
        auth: authRoles.admin
    },
    {
        path: '/role/create',
        element: <RoleCreate />,
        auth: authRoles.admin
    },
    {
        path: '/role/edit/:id',
        element: <RoleUpdate />,
        auth: authRoles.admin
    },
    {
        path: '/role/view/:id',
        element: <RoleView />,
        auth: authRoles.admin
    },
    {
        path: '/module',
        element: <Users name="module" />,
        auth: authRoles.admin
    },
    {
        path: '/module/create',
        element: <ModuleCreate />,
        auth: authRoles.admin
    },
    {
        path: '/module/edit/:id',
        element: <ModuleUpdate />,
        auth: authRoles.admin
    },
];

export default usersRoutes;