import ROLE_ACTION_TYPES from './role.types';

export const ROLE_INITIAL_STATE = {
  roleLists: [],
};

export const RoleReducer = (state = ROLE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ROLE_ACTION_TYPES.LOAD_ROLES: {
      return { ...state, roleLists: payload };
    }
    default:
      return state;
  }
};
