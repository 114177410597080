import PROFILE_ACTION_TYPES from './profile.types';

export const PROFILE_INITIAL_STATE = {
  profileList: [],
};

export const ProfileReducer = (state = PROFILE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_ACTION_TYPES.LOAD_PROFILE: {
      return { ...state, profileList: payload };
    }
    default:
      return state;
  }
};
