import GOLD_ACTION_TYPES from './gold.types';

export const GOLD_INITIAL_STATE = {
  goldList: [],
};

export const GoldReducer = (state = GOLD_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GOLD_ACTION_TYPES.LOAD_GOLD: {
      return { ...state, goldList: payload };
    }
    default:
      return state;
  }
};
