import CP_ACTION_TYPES from './cp.types';

export const CP_INITIAL_STATE = {
  cpList: [],
};

export const CPReducer = (state = CP_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CP_ACTION_TYPES.LOAD_CP: {
      return { ...state, cpList: payload };
    }
    default:
      return state;
  }
};
