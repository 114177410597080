import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from 'ui-component/Loadable';

const List = Loadable(lazy(() => import('./List')));
const Create = Loadable(lazy(() => import('./Create')));
const Update = Loadable(lazy(() => import('./Update')));
const View = Loadable(lazy(() => import('./View')))

const rawMaterialRoutes = [
    {
        path: '/raw-materials/create',
        element: <Create />,
        auth: authRoles.admin
    },
    {
        path: '/raw-materials',
        element: <List />,
        auth: authRoles.admin
    },
    {
        path: '/raw-materials/edit/:id',
        element: <Update />,
        auth: authRoles.admin
    },
    {
        path: '/raw-materials/view/:id',
        element: <View />,
        auth: authRoles.admin
    }
];

export default rawMaterialRoutes;