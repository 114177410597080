import DISH_ACTION_TYPES from './dish.types';

export const DISH_INITIAL_STATE = {
  dishList: [],
};

export const DishReducer = (state = DISH_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case DISH_ACTION_TYPES.LOAD_DISH: {
      return { ...state, dishList: payload };
    }
    default:
      return state;
  }
};
