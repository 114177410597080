import DULL_ACTION_TYPES from './dull.types';

export const DULL_INITIAL_STATE = {
  dullList: [],
};

export const DullReducer = (state = DULL_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case DULL_ACTION_TYPES.LOAD_DULL: {
      return { ...state, dullList: payload };
    }
    default:
      return state;
  }
};
