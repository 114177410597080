import MODULE_ACTION_TYPES from './module.types';

export const MODULE_INITIAL_STATE = {
  moduleLists: [],
};

export const ModuleReducer = (state = MODULE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case MODULE_ACTION_TYPES.LOAD_MODULES: {
      return { ...state, moduleLists: payload };
    }
    default:
      return state;
  }
};
