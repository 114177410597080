// project imports
import menuItem from 'menu-items';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const navItems = menuItem.items.map((item) => {
        return <NavItem key={item.id} item={item} level={1} />
    });

    return <>{navItems}</>;
};

export default MenuList;
