import PRODUCTSIZE_ACTION_TYPES from './product-size.types';

export const PRODUCTSIZE_INITIAL_STATE = {
  sizeList: [],
};

export const ProductSizeReducer = (state = PRODUCTSIZE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCTSIZE_ACTION_TYPES.LOAD_PRODUCTSIZE: {
      return { ...state, sizeList: payload };
    }
    default:
      return state;
  }
};
